.servicepage-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.servicepage-form-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.servicepage-btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.servicepage-btn-close::before {
  content: '✖';
}