/* Blogpage CSS */

.blogpage-container {
    padding: 20px;
  }
  
  .add-blog-btn {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .add-blog-btn:hover {
    background-color: #45a049;
  }
  
  .blog-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .blog-table th, .blog-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .blog-thumbnail, .blog-cover-image {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .edit-btn, .delete-btn {
    background-color: #ffcc00;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .delete-btn {
    background-color: #e74c3c;
  }
  
  .edit-btn:hover {
    background-color: #f39c12;
  }
  
  .delete-btn:hover {
    background-color: #c0392b;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    width: 500px; /* Adjust as per your requirement */
    max-height: 80vh; /* Ensures modal is not too tall */
    overflow-y: auto; /* Enables scrolling when content overflows */
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .form-input, .form-textarea, .form-file {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .submit-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  .cancel-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .cancel-btn:hover {
    background-color: #c0392b;
  }
  
 /* Resize images to smaller sizes */
.image-preview {
  width: 80px; /* Reduce size */
  height: 80px; /* Reduce size */
  margin-top: 10px;
  object-fit: cover; /* Ensures proper cropping */
}

.image-preview-container {
  text-align: center;
  margin-top: 10px;
}

/* Optional: Additional tweaks for image styling */
.image-label {
  font-weight: bold;
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

  
  .thumbnail-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover; /* This ensures the image fills the container */
  }
  .image-preview-container {
    text-align: center;
    margin-top: 10px;
  }
  
  .image-label {
    font-weight: bold;
    color: #333;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  
  .image-preview {
    width: 100px;
    height: auto;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    background: #f9f9f9;
  }
  