/* Ensure vertical scrolling remains */
.admin .user-details {
  overflow-y: auto;
  max-height: 90vh; /* Adjust height if needed */
}
/* Sidebar ke baad jo content area hai usko full width tak spread karo */
.admin .main-content {
  width: calc(100% - 250px); /* Sidebar width adjust */
  margin-left: auto;
  margin-right: 10px;
  padding: 20px;
}

/* Table container ko full width tak extend karo */
.admin .user-details .container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden; /* Horizontal scroll hata diya */
}
/* Table ko full width par set karo */
.admin .user-details .container table {
  width: 100%;
  table-layout: auto; /* Columns ko auto adjust karne do */
  white-space: normal; /* Text wrap hone do */
}
  
  /* Table styling */
  .table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
/* Table cells ka padding aur overflow adjust karo */
.table th, .table td {
  padding: 10px;
  text-align: left;
  word-wrap: break-word; /* Text wrap ho */
  overflow-wrap: break-word; /* Word wrap ensure ho */
}
  
  .table-striped tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .table-info {
    background-color: #e0f7fa; /* Highlight new contacts */
  }
  
 
/* Message column ke liye text wrap aur expand enable karo */
.message {
  max-width: none;
  white-space: normal;
}
  
  /* Button styling */
  .btn-link {
    padding: 0;
    color: #007bff;
    text-decoration: none;
  }
  
  .btn-link:hover {
    text-decoration: underline;
  }
  
  /* Pagination styling */
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .pagination button {
    margin: 0 5px;
  }
  
  /* Modal styling */
  .modal-content {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    max-width: 500px;
    margin: auto;
  }
  
  .modal-header {
    border-bottom: 1px solid #ddd;
  }
  
  .modal-header .close {
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body {
    font-size: 16px;
    white-space: pre-line; /* To maintain line breaks in the message */
  }
  
  /* Background overlay for modal */
  .modal.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    padding-top: 60px;
  }
  
  /* Pagination button styling */
  .pagination button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  .pagination button:focus {
    outline: none;
  }
  
  /* Custom modal close button */
  .close {
    color: #000;
    font-size: 30px;
    font-weight: bold;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  