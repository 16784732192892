
html {
  scroll-behavior: smooth;
}
body {
  
  font-family: 'TenorSans' !important;
}
a {
text-decoration: none !important;
}
ul, li {
padding: 0;
}

@font-face {
  font-family: 'TenorSans';
  src: url('../fonts/TenorSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.text-theme {
  color: #f58539;
}
.cursor-pointer {
  cursor: pointer;
}
.fixed-top {
  background-color: #000 !important;
  z-index: 555 !important;
}
.navbar {
  font-family: 'TenorSans';
  padding: 1rem 1.5rem;
  transition: background-color 0.3s ease;
}

.navbar-light {
  background-color: #000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-size: 1.8rem;
  color: #333;
}

.navbar-nav .nav-link {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 15px;
  transition: color 0.3s ease;
  text-transform: uppercase;
  padding: 34px 0;
}

.navbar-nav .nav-link:hover {
  color: #f58539; 
}

.navbar-nav .nav-link.active {
  font-weight: bold;
  color: #f58539; 
}

/* / Add Sticky Transition / */
.navbar.scrolled {
  background-color: #000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mega-menu-dropdown .mega-menu {
  left: 0;
  right: 0;
  width: 100%;
  top: 100%;
  position: absolute;
  background: #000;
  z-index: 1000;
  display: none; /* Hide by default */
  border-top: 2px solid #f58539;
}

.mega-menu-dropdown.nav-item:hover .mega-menu {
  display: block; /* Show menu on hover */
}
.mega-menu-dropdown.nav-item .mega-menu a {
  font-size: 18px;
  color: #FF9800 ;
}
.mega-menu-dropdown.nav-item .mega-menu p {
  font-size: 14px;
  color: #fff ;
  margin-bottom: 3px;
}

.mega-menu-dropdown .mega-menu .container {
  padding: 1rem;
}

.mega-menu-dropdown .mega-menu .col {
  min-width: 250px;
}




/****footer*******/


footer {
padding-top: 100px;
padding-bottom: 15px;
background-position: 0 -36px;
background-repeat: no-repeat;
background-size: 100%;
background-color: #000;
position: relative;
margin-top: 78px;
}
footer:before {
background-image: url(../img/ft-bg.svg);
content: "";
position: absolute;
top: -73px;
width: 100%;
height: 75px;
background-size: 100%;
background-repeat: no-repeat;
}
.footer-shape-1 {
position: absolute;
right: 24px;
top: -2px;
z-index: 0;
}
.footer-shape-1 img {
animation-name: rotateme;
animation-duration: 5s;
animation-iteration-count: infinite;
animation-timing-function: linear;
}
.footer-shape-3 {
position: absolute;
bottom: 15%;
left: 50%;
transform: translateY(-15%) translateX(-50%);
animation: animationFramesOne 10s infinite linear;
opacity: 25%;
}
@keyframes animationFramesOne {
0% {
  transform: translate(0, 0) scale(1);
  background-color: red;
}
25% {
  transform: translate(50px, 50px) scale(1.2);
  background-color: orange;
}
50% {
  transform: translate(100px, 0) scale(0.8);
  background-color: yellow;
}
75% {
  transform: translate(50px, -50px) scale(1.4);
  background-color: green;
}
100% {
  transform: translate(0, 0) scale(1);
  background-color: red;
}
}

@keyframes rotateme {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}
footer ul .nav-link
{
color:#CDC4D9;
}
.newsletter-form .input-newsletter {
display: block;
width: 100%;
background-color: #2E2141;
border: 1px solid #2E2141;
height: 65px;
padding: 20px;
border-radius: 15px;
outline: 0;
color: #CDC4D9;
transition: var(--transition);
}
.newsletter-form button {
border: none;
width: 100%;
border-radius: 15px;
padding: 18.8px 35px;
margin-top: 15px;
}
.default-btn {
padding: 15.8px 35px;
text-align: center;
color: #000 !important;
font-size: 16px;
font-weight: 600;
transition: .6s;
display: inline-flex;
align-items: center;
justify-content: center;
position: relative;
border-radius: 10px;
z-index: 0;
background: rgb(249,135,43);
background: -moz-linear-gradient(90deg, rgba(249,135,43,1) 0%, rgba(244,202,55,1) 51%, rgba(249,135,43,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(249,135,43,1) 0%, rgba(244,202,55,1) 51%, rgba(249,135,43,1) 100%);
background: linear-gradient(90deg, rgba(249,135,43,1) 0%, rgba(244,202,55,1) 51%, rgba(249,135,43,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9872b",endColorstr="#f9872b",GradientType=1);
box-shadow: none;
overflow: hidden;
white-space: nowrap;
border: none;
}
/* .default-btn:before {
content: "";
position: absolute;
top: 0;
bottom: 0;
left: 50%;
width: 550px;
height: 550px;
margin: auto;
background-color: #feb302;
border-radius: 50%;
z-index: -1;
transform-origin: top center;
transform: translateX(-50%) translateY(-5%) scale(0.4);
transition: transform 0.9s;
} */
.widget-social li a i {
color: #CDC4D9;
display: inline-block;
transition: var(--transition);
height: 35px;
width: 35px;
line-height: 35px;
text-align: center;
border-radius: 50px;
background-color: var(--black-color);
border: 1px solid #A66BFF;
font-size: 15px;
}
.widget-social li {
display: inline-block;
list-style-type: none;
margin-right: 5px;
}

.copyright p {
color: #CDC4D9;
font-size: 16px;
font-weight: 800;
padding: 44px 0;
margin-top: 61px;
margin-bottom: 0;
border-top: 1px solid #2E2141;
}

/********/
.bg-bectangle-top {
position: relative;
}
.bg-bectangle-top:before {
background-image: url(../img/ft-bg.svg);
content: "";
position: absolute;
top: -75px;
width: 100%;
height: 76px;
background-repeat: no-repeat;
background-size: 100%;
}

.bg-bectangle-bottom {
position: relative;
}

.bg-bectangle-bottom::after {
background-image: url(../img/bg-bectangle-bottom.svg);
content: "";
position: absolute;
bottom: -141px;
width: 100%;
height: 143px;
left: 0;
background-repeat: no-repeat;
background-size: 100%;
}

.main-slides-area {
  background: #000;
  padding: 159px 0 0 0;
  position: relative;
}
.main-slides-area::after {
  background-image: url(../img/slides-bg.svg);
  content: "";
  position: absolute;
  bottom: -186px;
  width: 100%;
  height: 187px;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  }
.main-slides-content {
padding-right: 50px;
margin-top: 70px;
}

.main-slides-content span {
font-size: var(--font-size);
font-weight: 600;
color: #feb302;
display: block;
margin-bottom: 18px;
font-family: var(--heading-font-family);
}

.main-slides-content h1 {
font-size: 40px;
font-weight: bold;
margin-bottom: 0;
color: #fff;
position: relative;
}

.main-slides-content h1 .overlay {
position: absolute;
left: 0;
top: 0;
background: var(--btn-gradient-color);
width: 100%;
height: 100%;
z-index: 1;
}

.main-slides-content p {
margin-top: 18px;
margin-bottom: 0;
color: #CDC4D9;
font-size: 15px;
}

.main-slides-content .slides-btn {
margin-top: 30px;
}

.main-slides-shape-1 {
position: absolute;
left: 125px;
top: 147px;
z-index: 1;
}
.main-slides-shape-1 img {
animation-name: rotateme;
animation-duration: 5s;
animation-iteration-count: infinite;
animation-timing-function: linear;
width: 74px;
}
@keyframes rotateme {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

/* .main-slides-shape-2 {
position: absolute;
top: 20%;
left: 50%;
transform: translateY(-20%) translateX(-50%);
z-index: 1;
animation: moveleftbounce 2s linear infinite;
} */

.main-slides-shape-2 {
position: absolute;
top: 28%;
left: 43%;
transform: translateY(-11%) translateX(-50%);
z-index: 1;
animation: moveleftbounce 2s linear infinite;
}
.main-slides-shape-2 img {
  width: 70px;
}

@keyframes moveleftbounce {
0%, 100% {
    transform: translateX(-40%) translateY(-25%);
}
50% {
    transform: translateX(-60%) translateY(-30%);
}
}

.main-slides-shape-3 {
position: absolute;
bottom: 10%;
right: 36%;
transform: translateY(-10%) translateX(-32%);
z-index: 1;
}
.main-slides-shape-3 img {
animation-name: rotateme;
animation-duration: 5s;
animation-iteration-count: infinite;
animation-timing-function: linear;
width: 109px;
}

@keyframes rotateme {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
.main-slides-shape-4 {
position: absolute;
bottom: -84px;
left: 108px;
z-index: 1;
transform: translateY(-10%) translateX(-20%);
animation: moveleftbounce 1s linear infinite;
}
.main-slides-shape-4 img {
width: 98px;
}
@keyframes moveleftbounce {
0%, 100% {
    transform: translateY(-10%) translateX(-20%);
}
50% {
    transform: translateY(-15%) translateX(-25%);
}
}

.main-slides-image  {
text-align: center;
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
justify-content: center;
align-items: center;
display: flex;
}
.main-slides-image img {
animation-name: moveDownContinuous;
animation-duration: 2s;
animation-iteration-count: infinite;
animation-direction: alternate;
animation-timing-function: ease-in-out;
width: 285px;
position: absolute;
}
@keyframes moveDownContinuous {
  from {
      transform: translateY(-5%);
  }
  to {
      transform: translateY(5%);
  }
}


.slides1 {
opacity: 1;
}

.slides2 {
opacity: 0;
}

/* .main-slides-bg:hover .slides1 {
opacity: 0;
} */

.main-slides-bg:hover .slides2 {
opacity: 1;
}

/*********/


.wt-we-do {
  padding: 195px 0 79px;
}
.wt-we-do-conten span{
font-size: 14px;
line-height: 18px;
font-weight: 700;
font-style: normal;
text-transform: uppercase;
letter-spacing: 0.13em;
color: #173070;
}

.wt-we-do-conten h1 {
font-size: 36px;
color: #173070;
font-weight: bold;
margin: 33px 0;
padding-right: 90px;
}

.wt-we-do-conten p {
color: #A3A3A3;
font-size: 15px;
font-weight: 800;

}

/**************/

/*********/

.services {
padding: 59px 0 0 0;
background-color: #000;
}
.services-conten span{
font-size: 14px;
line-height: 18px;
font-weight: 700;
font-style: normal;
text-transform: uppercase;
letter-spacing: 0.13em;
color: #fff;
}

.services-conten h1 {
font-size: 42px;
color: #fff;
font-weight: bold;
margin: 33px 0;
padding-right: 30px;
}
.services-conten h1 b
{
background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-conten p {
color: #fff;
font-size: 17px;
font-weight: 800;


}
.slick-center {
/* transform: scale(1.2, 1.2);
transform-origin: 50% 50%; */
background-color: #fff;
}
.services-item {
background-color: #2E2141;
padding: 10px;
border-radius: 15px;
margin-bottom: 25px;
position: relative;
transition: .6s;
margin: 0 7px;
}
.services-item .services-image {
overflow: hidden;
border-radius: 15px;
}
.services-item .services-image a img {
border-radius: 15px;
transition: .6s;
}
.services-item:hover .services-image a img {
transform: scale(1.1);
filter: blur(2px);
}

.services-content {
padding: 30px 15px 20px;
}

.services-content h3 {
font-size: 17px;
font-weight: bold;
margin-bottom: 15px;
}

.services-content h3 a {
color: #FEB302;
}

.services-content p {
color: #CDC4D9;
margin-bottom: 0;
}

.services-content .services-btn {
background: var(--btn-gradient-color);
/* -webkit-background-clip: text; */
-webkit-text-fill-color: transparent;
font-weight: bold;
margin-top: 20px;
transition: 6s;
position: relative;
}

.services-content .services-btn::before {
content: "";
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 0;
width: 20px;
height: 1.5px;
background: var(--btn-gradient-color);
transition: all ease 0.5s;
border-radius: 5px;
opacity: 0;
visibility: hidden;
transition: var(--transition);
}


/*****/

/******/


.who-we-are {
padding: 192px 0 116px 0;
background-color: #FFFBFC;
}
.who-we-are-conten span{
font-size: 14px;
line-height: 18px;
font-weight: 700;
font-style: normal;
text-transform: uppercase;
letter-spacing: 0.13em;
color: #173070;
}

.who-we-are-conten h1 {
font-size: 42px;
color: #173070;
font-weight: bold;
margin: 33px 0;
padding-right: 90px;
}

.who-we-are-conten p {
color: #A3A3A3;
font-size: 17px;
font-weight: 800;

}

/********/

/*****testimonials****/

.testimonials {
padding: 56px 0 0 ;
background-color: #000;
}
.testimonials-conten span{
font-size: 14px;
line-height: 18px;
font-weight: 700;
font-style: normal;
text-transform: uppercase;
letter-spacing: 0.13em;
color: #fff;
}

.testimonials-conten h1 {
font-size: 42px;
color: #ddd;
font-weight: bold;
margin: 33px 0;
padding-right: 90px;
}
.testimonials-conten h1 b {
background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
background-clip: text;
-webkit-text-fill-color: transparent;
}

.testimonials-conten p {
color: #fff !important;
font-size: 17px;
font-weight: 800;

}
.testimonials-item .item-box {
position: relative;
border-radius: 15px;
padding: 29px 26px 16px 122px;
margin-bottom: 0;
transition: var(--transition);
}
.testimonials-item .item-box img {
position: absolute;
left: 3px;
top: 28px;
max-width: 100px;
border-radius: 50%;
}
.testimonials-item .item-box p {
margin-bottom: 15px;
line-height: 1.8;
color: #fff !important;
font-weight: 500;
}
.testimonials-item .item-box h4 {
font-size: 13px;
margin-bottom: 0;
font-weight: 500;
color: #fff;
}


/******/
.blog-section {
overflow: hidden;
position: relative;
z-index: 1;
padding-top: 193px;
padding-bottom: 75px;
}
.blog-heading {
max-width: 630px;
margin: 0 auto 45px;
text-align: center;
position: relative;
}

.blog-heading  span {
font-size: 15px;
color: #173070;
font-weight: 600;
display: inline-block;
margin-bottom: 15px;
}
.blog-heading h2 {
font-size: 42px;
margin-bottom: 0;
line-height: 1.4;
position: relative;
font-weight: 800;
}
.blog-heading h2 b {
background: radial-gradient(circle,#a66bff,#c666ef,#dd62df,#ee61cf,#fb64bf);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.blog-slides .blog-card {
text-align: left;
margin-left: 10px;
margin-right: 10px;
padding: 0 10px;
}
.blog-card .blog-image {
overflow: hidden;
border-radius: 15px;
}
.blog-card .blog-image a {
display: block;
}
.blog-card .blog-image a img {
border-radius: 15px;
transition: .6s;
width: 100%;
}
.blog-card .blog-content .date {
display: inline-block;
font-size: 16px;
font-weight: 800;
padding: 12px 25px;
border-radius: 50px;
border: 1px solid #fff;
box-shadow: 0 15px 35px rgb(0 0 0 / 14%);
margin-bottom: 25px;
color: #3e019a;
transition: .6s;
}
.blog-card .blog-content h3 {
font-size: 22px;
line-height: 1.5;
margin-bottom: 10px;
font-weight: 700;
}
.blog-card .blog-content h3 a {
color: #000;
}

.blog-card .blog-content .blog-btn {
color: #000;
font-weight: 700;
transition: .6s;
position: relative;
margin-top: 20px;
font-size: 15px;
}
.contact-details-section {
background-color: #000;
color: #fff;
padding: 125px 0 0 0;
margin-bottom: 177px;
}
.sr-title {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  top: -100px;
  bottom: 100%;
  left: 0;
  right: 0;
}

.our-top-serv .our-title {
  text-align: center;
  margin-top: 79px;
  margin-bottom: 46px;
}
.card {
  background: #F1F2FA !important;
  padding: 12px 3px;
  border-radius: 40px !important;
  margin: 10px;
}
.our-top-serv .card-img-top {
  width: 80px;
  display: inline-block;
}
.card-title {
font-size: 15px;
font-weight: 900;
}
.card-text {
  font-size: 14px;
}


/********/


.contact-section {
  background: #fff;
  border-radius: 12px;
  padding: 57px 0;
overflow: hidden;
}

.contact-details {
padding: 30px;
background-color: #f9f5ff;
}

.contact-details h2 {
color: #0d0c22;
margin-bottom: 10px;
font-size: 38px;
font-weight: 600;
}

.contact-details p {
font-size: 16px;
color: #666;
margin-bottom: 20px;
}

.contact-details ul {
list-style: none;
padding-left: 0 !important;
}

.contact-details ul li {
display: flex;
align-items: center;
margin-bottom: 15px;
font-size: 16px;
}

.contact-details ul li i {
font-size: 20px;
color: #6a0dad;
margin-right: 15px;
}

.contact-form {
padding: 30px;
}

.contact-form form {
display: flex;
flex-direction: column;
gap: 15px;
}

.contact-form input, .contact-form select,
.contact-form textarea {
width: 100%;
padding: 12px;
border:none !important;
border-radius: 0px !important;
font-size: 16px;
border-bottom: 1px solid #ccc !important;
}
.contact-form .form-group{
position: relative
}
.contact-form .icon {
position: absolute;
left: 15px;
top: 12px;
}
.contact-form input {
padding-left: 26px !important;
}
.contact-form textarea {
resize: none;
height: 120px;
padding-left: 39px;
}

.contact-form label {
font-size: 14px;
color: #555;
}
:focus-visible {
outline: none;
}



.partners {
grid-column: span 2;
display: flex;
justify-content: space-evenly;
align-items: center;
padding: 20px;
}
.partnerlg {
background: #dddddd;
width: 166px;
height: 67px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #e1e1e1;
}
.partners img {
max-height: 40px;
}

.partners img:hover {
opacity: 1;
}

.services-section .imgsr {
  height: 120px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.services-section .sec-card-conten {
  margin-top: -107px;
  z-index:11;
  position: relative;
}

/***blog-banner***/
.blog-banner {
  height: 350px;
  overflow: hidden;
}

.blog-banner-title {
  position: absolute;
  top: 52px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}
.blog-details-content {
  margin: 40px;
}
.blog-details-content h1 {
  font-size: 25px;
  font-weight:bold !important;
}
.blogcoverimage  {
  border-radius: 11px;
  box-shadow: 2px 2px 17px -10px;
}
.blog-details-content .blog-published-dt {
  font-size: 14px;
  margin: 19px 1px 18px;
  color: #5b5b5c;
}
.blog-details-content .blog-content {
  font-size: 14px;
  line-height: 26px;
  margin-top: 34px;
}
.blog-content h4 {
  margin-bottom: 79px !important;
  font-size: 55px !important;
}
/***blog-banner***/

/******/
.project-client-conten {
  margin-bottom: 165px;
  margin-top: 40px;
}
.project-client-conten .card {
  border-radius: 20px !important;
}
.project-client-conten .card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.project-client-conten .card h5 {
  font-size: 16px;
  font-weight: bold;
}


.project-client-conten .card .box{
  position: relative;
}
.project-client-conten .card .box:before {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 100%; 
  background-color: #fff;
}
.project-client-conten .card .box:last-child::before {
  content: none; /* Removes the pseudo-element */
  display: none; /* Hides it */
}

/********/

/* Right Sidebar Modal */


.content-box {
  background: #f4f4f4;
  width: 500px;
  position: fixed;
  transition: transform 0.3s;
  transform: translateX(100%); 
  top: 0;
  right: 0;
  overflow: auto;
  height: 100vh;
  padding: 31px;
  z-index: 666;
}

.content-box.active {
  
  transform: translateX(0);
}
.content-box input.form-control {
  border-radius: 0;
  font-size: 16px;
  height: 50px;
}
.contact-info svg {
  color: #f58539;
  font-size: 18px;
  margin-right: 4px;
}
.contact-info p {
  margin-bottom: 16px ;
  font-weight: 600;
  font-size: 14px;
}
.content-box .crose {
  position: absolute;
  right: 8px;
  top: 24px;
  background: #000;
  color: #fff;
  border-radius: 35px;
  border: none;
  padding: 4px 10px 8px;
  cursor: pointer;
}
.content-box .widget-social li a i{
  background-color: #FF9800;
  color: #fff;
  border: none;
}
.msg-btn {
  position: fixed;
  right: 0;
  top: 231px;
  background: #f58539;
  border: none;
  padding: 5px 14px 11px;
  color: #fff;
  font-size: 25px;
}

/*********testimoniyal*********/

.gallery-container {
  position: relative;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.gallery-gradient {
  position: absolute;
  top: 0;
  height: 100%;
  width: 48px;
  z-index: 10;
}

.gallery-gradient-left {
  left: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, #060606 100%);
}

.gallery-gradient-right {
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #060606 100%);
}

.gallery-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.gallery-track {
  display: flex;
  height: auto;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transform-style: preserve-3d;
  width: 100%;
}

.gallery-item {
  position: absolute;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: center;
  padding: 8%;
  backface-visibility: hidden;
}

.gallery-img {
  pointer-events: none;
  height: 120px;
  width: 300px;
  border-radius: 15px;
  border: 3px solid #fff;
  object-fit: cover;
  transition: 0.3s ease;
}

.gallery-item:hover .gallery-img {
  transform: scale(1.05);
  transition: 0.3s ease;
}

@media (max-width: 768px) {
  .gallery-item {
    padding: 6%;
  }
  .gallery-img {
    height: 100px;
    width: 220px;
  }
}

/********End*********/
/* .slide-item {
  text-align: center;
  padding: 20px;
  background: #ddd; 
  transition: background 0.3s ease-in-out;
  border-radius: 10px;
}

.slick-center .slide-item {
  background: #000; 
  border: 1px solid #ff5722;
  color: white;
  transform: scale(1.1); 
}
.slick-center {

  padding: 16px 38px !important;
}
.slick-initialized .slick-slide {
  display: block;
  padding: 11px;
} */

.testimonial .slick-slide {
  transition: transform 0.5s ease-in-out;
  text-align: center;
  background: linear-gradient(to right, #4D4C4F, #244861);
  border-radius: 20px;
  padding: 40px 20px; /* TOP-BOTTOM PADDING */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid;
  border: 2px solid #F58539;
  border-image-slice: 1;
  margin: 0 15px; /* LEFT-RIGHT SPACING */
}

/* Center Slide Effect */
.testimonial .slick-center {
  /* transform: scale(1.2); */
  opacity: 1;
}

/* Slick List - Overflow Fix */
.testimonial .slick-list {
  padding: 40px 0 !important; /* UPAR-NICHE PADDING */
}

/* Slick Track - Alignment Fix */
.testimonial .slick-track {
  display: flex !important;
  align-items: center;
}

/****/
.benefit-img-wrapper {    
  display: inline-block;
  padding: 11px;
}
.benefit-img-wrapper .img-fluid {
    width:33px;
}
.benefit-img-wrapper.benefit-1 {
  background-color: #E7F2FF;
}
.benefit-img-wrapper.benefit-2 {
  background-color: #F1F7E8;
}
.benefit-img-wrapper.benefit-3 {
  background-color: #EFF2F5;
}
.benefit-img-wrapper.benefit-4 {
  background-color: #FFEEED;
}
.product-designer-dt {
  margin-top: 48px;
}
.product-designer-dt .card {
  border-radius: 10px !important;
  background-color: #f4f4f4 !important;
}
.job-apply {
  margin-top: 80px;
  margin-bottom: 142px;
}
.job-apply .form-control {
  border-radius: 0;
  height: 40px;
}

.invoice-section {
  background: linear-gradient(to bottom, #FFFFFF, #0283C4);
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}
/* .invoice-section:before {
  content: "";
  background-image: url("../img/ubikon-logo.png");
  top: 0;
  position: absolute;
  background-size: 70%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
} */

.service-dts {
  background-color: #1A1A1A;
}
.featurs, .why_Choose {
  background: #1A1A1A;
}
.featurs .card, .why_Choose .card {
  background-color: #252525 !important;
  color: #fff;
  border-radius: 15px !important;
}

.featurs .card svg, .why_Choose .card svg {
  color: #E0C053;
}
.featurs .card h5, .why_Choose .card h5 {
  color: #F58539;
}


/******/

.use-cases {
  background: #252525;
}
.use-cases .card {
  background-color: #1A1A1A !important;
  color: #fff;
  border-radius: 15px !important;
}

.use-cases .card svg {
  color: #E0C053;
}
.use-cases .card h5 {
  color: #fff;
}

.technologies-bhd .card {
  border-radius: 14px !important;
  border: 2px solid #4e5254 !important;
  box-shadow: none !important;
  background-color: #f4f4f4 !important;
}

.technologies-bhd .card h2{
  font-size: 20px;
  color: #f58539;
}
.technologies-bhd .card p{
  font-size: 14px;
  margin-bottom: 0 !important;
}

.react-icon-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.react-circle {
  width: 20px;
  height: 20px;
  background-color: #61dafb;
  border-radius: 50%;
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 2;
}

.react-ellipse {
  position: absolute;
  width: 100px;
  height: 50px;
  border: 4px solid #61dafb;
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

.ellipse1 {
  transform: rotate(0deg);
}

.ellipse2 {
  transform: rotate(60deg);
}

.ellipse3 {
  transform: rotate(-60deg);
}
.react-icon {
  animation: spin 5s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}
