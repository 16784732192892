/* Container Styling */
/* .container {
    max-width: 100%;
    padding: 20px;
} */

/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
}

table th,
table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

table th {
    background-color: #f4f4f4;
}

table img {
    border-radius: 5px;
}

/* Actions Buttons - Only for Table Buttons */
.table-actions button {
    cursor: pointer;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

/* Form Submit Button */
.form-container button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: red;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
}

button:hover {
    opacity: 0.8;
}

/* Fullscreen Form Styling */
.fullscreen-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Full Page Form on Desktop */
.form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 100vh; /* Full screen height */
    max-width: 100%;
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Scrollable if content is large */
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: red;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
}

/* Form Fields */
.form-container input,
.form-container select,
.form-container textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Image Preview */
.image-preview {
    display: flex;
    gap: 5px;
    margin-top: 10px;
}

.image-preview img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Submit Button */
.form-container button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 12px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
}

/* Responsive - Mobile View */
@media (max-width: 768px) {
    .form-container {
        width: 90%;
        height: auto;
        max-height: 90vh;
    }
}
