/*** Variables ***/
$sidebar-width: 250px;
$sidebar-bg: #222;
$sidebar-hover-bg: #444;
$header-bg: white;
$header-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$card-bg: white;
$card-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$badge-bg: red;
$badge-color: white;
$badge-font-size: 12px;

.admin {
    /*** Sidebar ***/

    .sidebar {
        width: $sidebar-width;
        background: $sidebar-bg;
        color: white;
        padding: 20px;
        height: 100vh;
        position: fixed;
        left: 0;

        h2 {
            text-align: center;
            margin-bottom: 20px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding: 12px;
                border-radius: 5px;

                a {
                    color: white;
                    text-decoration: none;
                    font-size: 18px;
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                    }

                    &:hover,
                    &.active {
                        background: $sidebar-hover-bg;
                    }
                }
            }
        }
    }

    /*** Main Content ***/
    .main-content {
        margin-left: $sidebar-width + 20px;
        width: 100%;
        padding: 20px;
    }

    /*** Header ***/
    .header-ad {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $header-bg;
        padding: 15px;
        box-shadow: $header-shadow;
        border-radius: 10px;
    }

    /*** Notification ***/
    .notification {
        position: relative;
        font-size: 22px;
        cursor: pointer;
        margin-right: 20px;

        .badge {
            position: absolute;
            top: -5px;
            right: -5px;
            background: $badge-bg;
            color: $badge-color;
            font-size: $badge-font-size;
            padding: 4px;
            border-radius: 50%;
        }
    }

    /*** Profile ***/
    .profile {
        position: relative;
        cursor: pointer;

        .profile-pic {
            display: flex;
            align-items: center;
            font-size: 18px;

            svg {
                font-size: 24px;
                margin-right: 8px;
                color: #555;
            }
        }
    }

    /*** Dropdown ***/
    .dropdown-menu {
        position: absolute;
        top: 50px;
        right: 0;
        background: $header-bg;
        width: 160px;
        box-shadow: $header-shadow;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        a {
            padding: 12px;
            color: #333;
            text-decoration: none;
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }

            &:hover {
                background: #f4f4f4;
            }
        }
    }

    /*** Dashboard ***/
    .dashboard {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
        margin-top: 20px;
    }

    .card {
        background: $card-bg;
        padding: 20px;
        border-radius: 10px;
        box-shadow: $card-shadow;
        text-align: center;
    }

}