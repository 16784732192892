/* Container for floating chat button */
.chatbot-container {
    position: fixed;
    bottom: 0px;
    right: -6px;
    z-index: 556; /* Ensure the chatbot is always above other content */
  }
  
  /* Floating chat button */
  .chatbot-btn {
    background: none;
    border: none;
    color: white;
    border-radius: 50%;
    width: 140px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    
  }
  
  /* .chatbot-btn:hover {
  } */
  
  /* Main chatbot window */
  .chatbot {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 350px;
    max-width: 90vw;
    height: 450px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  /* Chat header */
  .chat-header {
    background-color: #007bff;
    color: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px 15px 0 0;
  }
  
  .chat-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .close-chat {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
  }
  
  .close-chat:hover {
    opacity: 0.8;
  }
  
  /* Chat history */
  .chat-history {
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #f9f9f9;
  }
  
  .message {
    margin-bottom: 15px;
    display: flex;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message.bot {
    justify-content: flex-start;
  }
  
  .message p {
    max-width: 80%;
    padding: 12px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .message.user p {
    background-color: #007bff;
    color: white;
    border-radius: 12px 12px 0 12px;
  }
  
  .message.bot p {
    background-color: #e1e1e1;
    color: #333;
    border-radius: 12px 12px 12px 0;
  }
  
  /* Typing indicator */
  .typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .typing-indicator span {
    font-size: 24px;
    margin: 0 2px;
    animation: blink 1.4s infinite;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Input area */
  .chat-input {
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 10px;
    outline: none;
  }
  
  .chat-input button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .chatbot {
      bottom: 70px;
      right: 10px;
      width: 90%;
    }
  
    .chatbot-btn {
      width: 50px;
      height: 50px;
      font-size: 24px;
    }
  }