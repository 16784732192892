/*** Variables ***/
/* .admin { */
  /*** Sidebar ***/
  /*** Main Content ***/
  /*** Header ***/
  /*** Notification ***/
  /*** Profile ***/
  /*** Dropdown ***/
  /*** Dashboard ***/ 
/* } */
  .admin .sidebar {
    width: 250px;
    background: #222;
    color: white;
    padding: 20px;
    height: 100vh;
    position: fixed;
    left: 0;
    overflow-y: auto; /* Enables vertical scrollbar */
    max-height: 100vh; /* Ensures full height */
  }
  
  /* Custom scrollbar styling */
  .admin .sidebar::-webkit-scrollbar {
    width: 6px;
  }
  
  .admin .sidebar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .admin .sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
    .admin .sidebar h2 {
      text-align: center;
      margin-bottom: 20px; }
    .admin .sidebar ul {
      list-style: none;
      padding: 0; }
      .admin .sidebar ul li {
        padding: 12px;
        border-radius: 5px; }
        .admin .sidebar ul li a {
          color: white;
          text-decoration: none;
          font-size: 18px;
          display: flex;
          align-items: center; }
          .admin .sidebar ul li a svg {
            margin-right: 10px; }
          .admin .sidebar ul li a:hover, .admin .sidebar ul li a.active {
            background: #444; }
  .admin .main-content {
    margin-left: 270px;
    width: 100%;
    padding: 20px; }
  .admin .header-ad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
  .admin .notification {
    position: relative;
    font-size: 22px;
    cursor: pointer;
    margin-right: 20px; }
    .admin .notification .badge {
      position: absolute;
      top: -5px;
      right: -5px;
      background: red;
      color: white;
      font-size: 12px;
      padding: 4px;
      border-radius: 50%; }
  .admin .profile {
    position: relative;
    cursor: pointer; }
    .admin .profile .profile-pic {
      display: flex;
      align-items: center;
      font-size: 18px; }
      .admin .profile .profile-pic svg {
        font-size: 24px;
        margin-right: 8px;
        color: #555; }
  .admin .dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    width: 160px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column; }
    .admin .dropdown-menu a {
      padding: 12px;
      color: #333;
      text-decoration: none;
      display: flex;
      align-items: center; }
      .admin .dropdown-menu a svg {
        margin-right: 10px; }
      .admin .dropdown-menu a:hover {
        background: #f4f4f4; }
  .admin .dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px; }
  .admin .card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center; }


